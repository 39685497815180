<template>
  <el-card class="iot-dtu iot-search-header public-page-style">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
       
        <el-form-item>
          <el-input
            size="mini"
            clearable
            v-model="getForm.dtuSn"
            style="width:180px"
            placeholder="请输入查询的序列号"
          ></el-input>
        </el-form-item>
         <el-form-item>
          <el-input
            size="mini"
            clearable
             style="width:180px"
            v-model="getForm.thirdSn"
            placeholder="请输入第三方序列号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="selectData()"
            >查询</el-button
          >
        </el-form-item>
       
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      border=""
      class="dtu-tab"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      height="650"
    >
      <el-table-column
        prop="projectName"
        label="项目名称"
        sortable
      ></el-table-column>
    
      <el-table-column
        prop="dtuSn"
        label="序列号"
 
        sortable
      ></el-table-column>
      <el-table-column
        prop="thirdSn"
        label="第三方序列号"
      
      ></el-table-column>
          <el-table-column
        prop="dtuId"
        label="设备ID"
        sortable
      ></el-table-column>
     
      <!-- <el-table-column label="操作" width="80" fixed="right" v-if="$store.state.userId==1">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="upgrade(scope.row.dtuId)"
            >升级</el-button
          >
          
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="getForm.size"
      :total="getForm.total"
      layout="total, sizes, prev, pager, next, jumper"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    ></el-pagination>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";

let that;
export default {
  name: "CreateDtu",
  data() {
    return {

      theme: { TABLE_HEADER_COLOR },
      tableData: [],
      getForm: {
        size: 10,
        current: 1,
        total: 0,
        dtuSn: "",
        thirdSn:"",
      },

    };
  },
  methods: {
    handleSizeChange(val) {
      that.$set(that.getForm, "size", val);
      that.$set(that.getForm, "current", 1);
      that.getThirdPartPage(that.getForm);
    },
    handleCurrentChange(val) {
      that.$set(that.getForm, "current", val);
      that.getThirdPartPage(that.getForm);
    },
    selectData() {
      that.$set(that.getForm, "current", 1);
      that.getThirdPartPage(that.getForm);
    },
    getThirdPartPage() {
      that.$api.dtu.getThirdPartPage(that.getForm).then((response) => {
        if (response.code == 200) {
          that.tableData = response.data.records;
          that.getForm.total = response.data.total;
        }
      });
    },
    upgrade(dtuId) {
        that
        .$confirm("确定升级？", "提示", {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
          cancelButtonClass:"cancelBtn",
        })
        .then(() => {
      that.$api.dtu.upgrade(dtuId).then((response) => {
        if (response) {
          that.$message({
            type: "success",
            message: response.msg,
          })
        }
        
      });
      })
        .catch(() => {});
     
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getThirdPartPage();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-dtu-create {
  float: right;
  margin-top: 8px;
}
.iot-dtu-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-dtu >>> .iot-step-body {
  width: 100%;
  height: calc(100% - 40px);
  padding: 30px;
}

.iot-dtu >>> .el-dialog__body {
  width: 95%;
  height: 90%;
  overflow: hidden;
}

.dtu-tab {
  overflow-x: auto;
}
.iot-dtu >>> .el-form-item {
  vertical-align: middle;
}
</style>
